interface Iprops {
    style?: any
}
export const Logo = ({ style }: Iprops) => {
    return (
        <div className="logoer">

            <img
                style={style}
                alt="Chalo Ride"
                src="assets/top-logo.png"
            />

        </div>
    )
}

export default Logo;