import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <footer>
      <IonGrid style={{ background: "rgb(29 29 29)", color: "#fff" }}>
        <IonRow>
          <IonCol sizeMd="3" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Company</h4>
              <ul>
                <li>
                  <a href="http://www.visakharental.com">About Us</a>
                </li>
                <li>
                  <a href="http://www.visakharental.com">Privacy Policy</a>
                </li>
                <li>
                  <a href="http://www.visakharental.com">Terms & Conditions</a>
                </li>
              </ul>
            </article>
          </IonCol>
          <IonCol sizeMd="3" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Our Presence</h4>
              <ul className="footer_links">
                <li><a href="http://www.visakharental.com/bike-rentals-in-vizag">Bike Rentals near R.T.C Complex, Vizag</a></li>
                <li><a href="http://www.visakharental.com/bike-rentals-in-vizag">Bike Rentals near R.K Beach, Vizag</a></li>
                <li><a href="http://www.visakharental.com/bike-rentals-in-vizag">Bike Rentals in Aruku</a></li>
                <li><a href="http://www.visakharental.com/bike-rentals-in-vizag">Bike Rentals in Vizag</a></li>
                <li><a href="http://www.visakharental.com/bike-rentals-in-vizag">Car Rentals in Vizag</a></li>
              </ul>
            </article>
          </IonCol>
          <IonCol sizeMd="3" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Follow US</h4>
              <ul>
                <li>
                  <a target="_blank" href="https://youtube.com/@visakharental?si=NICjm3mKU5MCZ7_1"><IonIcon name="logo-youtube"></IonIcon> Youtube</a>
                </li>
                <li>
                  <a target="_blank" href="https://www.facebook.com/profile.php?id=100094189077096&mibextid=ZbWKwL"><IonIcon name="logo-facebook"></IonIcon> Facebook</a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/visakharental?igsh=aHo0dG80a3pmNzF6"><IonIcon name="logo-instagram"></IonIcon> Instagram</a>
                </li>
              </ul>
            </article>
          </IonCol>
          <IonCol sizeMd="3" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Contact US</h4>
              <ul>
                <li>
                  <p>
                    <strong>
                      <IonIcon name="time-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;06.00AM (Open) - 08.00PM (Closed)
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <IonIcon name="call-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;+91 9912512571
                  </p>
                  <p>
                    <strong>
                      <IonIcon name="call-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;+91 9912512579
                  </p>
                </li>
                <li>
                  <>
                    <strong>
                      <IonIcon name="storefront-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;
                    <address title="Address: Abhayaa Hotel Cellar, Beside Hp Petrol Bunk, Opp. Rtc Complex, Asilmetta Junction, Vizag.">Address: Abhayaa Hotel Cellar, Beside Hp Petrol Bunk, Opp. Rtc Complex, Asilmetta Junction, Vizag.</address>
                  </>
                </li>
              </ul>
            </article>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid style={{ background: "rgb(24 24 24)", color: "#fff" }}>
        <IonRow>
          <IonCol sizeMd="12" sizeSm="12" sizeXs="12">
            <article style={{ margin: "auto" }}>
              <p style={{ textAlign: "center", fontSize: "14px" }}>
                Copyright © 2024 -{" "}
                <a href="http://www.visakharental.com">visakha rental{"  "}</a> - All rights
                reserved.
              </p>
            </article>
          </IonCol>
        </IonRow>
      </IonGrid>
    </footer>
  );
};

export default Footer;
