export const bikeCollections = [
  {
    id: 1,
    name: "Honda Dio (BS4)",
    category: "scooty, honda, dio",
    filter: "scooty, 5G, honda, dio",
    image: "assets/bikes/Honda-Dio-3.png",
    exceed: 'if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 499 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 499 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 450 * 4, kms: "400 Kms" },
      { id: 1.6, duration: "5 Days", price: 450 * 5, kms: "500 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 2799,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 6999,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 2,
    name: "Honda Activa 5G",
    category: "scooty, activa, 5G",
    image: "assets/bikes/activaa-5g.jpeg",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 499 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 499 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 450 * 4, kms: "400 Kms" },
      { id: 1.6, duration: "5 Days", price: 450 * 5, kms: "500 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 2799,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 6999,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 3,
    name: "Hero Maestro (BS4)",
    category: "scooty, Hero, Maestro, 5G",
    image: "assets/bikes/candy-blazing-red-hero-maestro-edge-right-side-view.png",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 499, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 499 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 499 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 1800, kms: "400 Kms" },
      { id: 1.6, duration: "5 Days", price: 2250, kms: "500 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 2799,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 6999,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 4,
    name: "Honda Dio (BS6)",
    category: "scooty, honda, dio, bs6",
    filter: "scooty",
    exceed: 'if exceeds Rs.4/KM',
    image: "assets/bikes/dio-right-side-view.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 2160, kms: "400 Kms" },
      { id: 1.6, duration: "5 Days", price: 2700, kms: "500 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },

  {
    id: 5,
    name: "Honda Activa 6G",
    category: "scooty, honda, 6g, 5g, 4g",
    filter: "scooty",
    exceed: 'if exceeds Rs.4/KM',
    image: "assets/bikes/activa-6g.jpeg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 2160, kms: "400 Kms" },
      { id: 1.6, duration: "5 Days", price: 2700, kms: "500 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 6,
    name: "TVS Jupiter (BS6)",
    category: "scooty, tvs, Jupiter",
    image: "assets/bikes/tvs-jupiter-1000x1000.jpg",
    filter: "scooty",
    exceed: 'if exceeds Rs.4/KM',
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 2160, kms: "400 Kms" },
      { id: 1.6, duration: "5 Days", price: 2700, kms: "500 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 7,
    name: "Suzki Access 125cc",
    filter: "scooty",
    category: "scooty, Suzki, Access, 125",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/SUZUKI_ACCESS_125_(2019).png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 699 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 699 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 699 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 699 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 4199,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 9799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 8,
    name: "TVS Ntorq 125cc",
    category: "scooty, TVS, Ntorq, 125cc",
    filter: "scooty",
    exceed: 'if exceeds Rs.3/KM',
    image: "assets/bikes/ntorq-125-right-side-view-11.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 699 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 699 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 699 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 699 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 4199,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 9799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 9,
    name: "Suzuki Avenis 125cc",
    category: "scooty, Suzuki, avenis, 125",
    filter: "scooty",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/avenis-GLOSSY-Sparkle-Black.png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 699 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 699 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 699 * 4, kms: "480 Kms" },
      { id: 1.6, duration: "5 Days", price: 699 * 5, kms: "600 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 4199,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 9799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 10,
    name: "Bajaj CT 110x",
    category: "bike, bajaj, 110cc",
    filter: "bike",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/BajajCT110XES-2_2ef2e176-2848-4f56-9e8f-479ad8e535b0.webp",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 599, kms: "120 Kms" },
      { id: 1.2, duration: "2 Days", price: 599 * 2, kms: "240 Kms" },
      { id: 1.3, duration: "3 Days", price: 599 * 3, kms: "360 Kms" },
      { id: 1.4, duration: "4 Days", price: 2400, kms: "400 Kms" },
      { id: 1.6, duration: "5 Days", price: 3000, kms: "500 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 3500,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 8400,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 11,
    name: "Honda Shine 125cc",
    category: "bikes, honda, Shine, 125cc",
    exceed: 'if exceeds Rs.5/KM',
    filter: "bike",
    image: "assets/bikes/honda-shine-125cc-bike.png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 1400, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 2100, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 2800, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 3500, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 4200,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 9799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 12,
    name: "Honda SP Shine 125cc",
    category: "bikes, honda, Shine, 125cc",
    filter: "bike",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/Honda-SP-Shine-125cc-sp.jpg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 699, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 1400, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 2100, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 2800, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 3500, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 4200,
        kms: "700 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 9799,
        kms: "2,000 Kms",
      },
    ],
  },
  {
    id: 13,
    name: "Bajaj NS 160cc",
    category: "bikes, Bajaj, NS, 160cc",
    filter: "bike",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/bajaj-ns.png",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 999, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 1999, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 2999, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 4999, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 5999, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 6499,
        kms: "1000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 16799,
        kms: "2,500 Kms",
      },
    ],
  },
  {
    id: 14,
    name: "Royal Enfield Classic 350",
    exceed: 'if exceeds Rs.5/KM',
    category: "bikes, Royal, Enfield, Classic, 350cc",
    filter: "bike",
    image: "assets/bikes/royal-enfield.jpeg",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 1199, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 2399, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 3599, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 4799, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 5999, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 7700,
        kms: "1000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 22499,
        kms: "2,500 Kms",
      },
    ],
  },
  {
    id: 15,
    name: "Hero Xpulse 4V 200cc",
    category: "bikes, Xpulse, hero",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/xpulse-hero.jpg",
    filter: "bike",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 1399, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 2799, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 4199, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 5599, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 6999, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 8399,
        kms: "1000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 22499,
        kms: "2,500 Kms",
      },
    ],
  },
  {
    id: 16,
    name: "Royal Enfield Hunter 350",
    category: "bikes, Royal, Enfield, Hunter 350cc",
    exceed: 'if exceeds Rs.5/KM',
    image: "assets/bikes/rebel-red.webp",
    filter: "bike",
    priceDetails: [
      { id: 1, duration: "1 Day", price: 1499, kms: "150 Kms" },
      { id: 1.2, duration: "2 Days", price: 2999, kms: "300 Kms" },
      { id: 1.3, duration: "3 Days", price: 4499, kms: "450 Kms" },
      { id: 1.4, duration: "4 Days", price: 5999, kms: "600 Kms" },
      { id: 1.6, duration: "5 Days", price: 7499, kms: "750 Kms" },
      {
        id: 2,
        duration: "1 Week",
        price: 9100,
        kms: "1000 Kms",
      },
      {
        id: 5,
        duration: "4 Week",
        price: 25200,
        kms: "2,500 Kms",
      },
    ],
  },
]

