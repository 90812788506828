import {
  IonContent,
  IonIcon,
  IonPage,
} from "@ionic/react";
import Footer from "./Footer";
import "./Layout.css";

const Layout: React.FC = ({ children }) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          className="ionic-header"
        >
        </div>
        <div className="top-header">
            <div><IonIcon icon="logo-whatsapp" title="visakha rental whats app" onClick={() => window.location.href = "https://wa.me/+919912512579?text=Hi visakha rental, Im looking for hire rental"} /></div>
            <div onClick={() => window.location.href = "tel:+919912512571"}><IonIcon icon="call" title=" call to +91 9912512571" />&nbsp;<span style={{ fontSize: '15px', fontWeight: "bolder" }}>+91 9912512571</span></div>
            <div style={{ borderRight: '0' }} onClick={() => window.location.href = "tel:+919912512579"}><IonIcon icon="call" title=" call to +91 9912512579" />&nbsp;<span style={{ fontSize: '15px', fontWeight: "bolder" }}>+91 9912512579</span></div>
          </div>
        {children}
        <div className="bikes-display-section">
          <h2 className="section-caption">Our Location</h2>
          <iframe title="visakha rental" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15201.264856413318!2d83.2935212505863!3d17.72973363146947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3943741d45f147%3A0x3051e0b43f3661d9!2sVisakha%20Bike%20%26%20Car%20Rentals!5e0!3m2!1sen!2sin!4v1726245389585!5m2!1sen!2sin" width="600" height="450"  loading="lazy"></iframe>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
  
};

export default Layout;
